import { BlockType } from '@cyber/service/pwa';

export const KOL_TWITTER_FOLLOWER_COUNT_THRESHOLD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 5000 : 1;

export const DEFAULT_BLOCK_TITLE = {
  [BlockType.Contact]: 'Contact',
  [BlockType.Credential]: 'Credentials Showcase',
  [BlockType.Education]: 'Education',
  [BlockType.WorkExperience]: 'Work Experience',
  [BlockType.NftCollection]: 'NFT Collection',
  [BlockType.NftGallery]: 'NFT Gallery',
  [BlockType.Posts]: 'Post',
  [BlockType.Event]: 'Event',
  [BlockType.Snapshot]: 'Snapshot Proposals',
  [BlockType.Skill]: 'Skills',
  [BlockType.Superlink]: 'Featured Content',
  [BlockType.Link]: 'Official Links',
  [BlockType.W3StGallery]: 'My W3ST',
  [BlockType.Twitter]: 'Other Showcase',
  [BlockType.Recommendation]: 'Recommendations',
};

export const sectors = [
  'NFT',
  'Identity',
  'Game',
  'DeFi',
  'Social',
  'Infrastructure',
  'Tool',
  'Wallet',
  'Metaverse',
  'DAO',
  'Blockchain',
];

export enum ChainType {
  eth = 'eth',
  polygon = 'polygon',
  bnb = 'bnb',
  optimism = 'optimism',
  arbitrum = 'arbitrum',
  fantom = 'fantom',
  avalanche = 'avalanche',
  gnosis = 'gnosis',
  solana = 'solana',
  Terra = 'Terra',
  Near = 'Near',
  Polkadot = 'Polkadot',
  Kusama = 'Kusama',
  Cardano = 'Cardano',
  Cosmos = 'Cosmos',
  Tron = 'Tron',
  Algorand = 'Algorand',
  Stellar = 'Stellar',
  Flow = 'Flow',
  EOS = 'EOS',
  Harmony = 'Harmony',
  Neo = 'Neo',
  Zilliqa = 'Zilliqa',
  Enjin = 'Enjin',
  Loopring = 'Loopring',
  ImmutableX = 'ImmutableX',
  Ronin = 'Ronin',
  Celo = 'Celo',
  Moonbeam = 'Moonbeam',
  Palm = 'Palm',
  Caduceus = 'Caduceus',
  ZkSync = 'ZkSync',
  Aptos = 'Aptos',
  Sui = 'Sui',
  StarkNet = 'StarkNet',
  Shardeum = 'Shardeum',
  ArbitrumNova = 'Arbitrum Nova',
  haqq = 'haqq',
  metis = 'Metis',
  injective = 'Injective',
  mantle = 'Mantle',
  scroll = 'Scroll',
  base = 'Base',
  sei = 'Sei',
  astar = 'Astar',
}

export const chainData: Record<string, Record<string, string>> = {
  [ChainType.eth]: {
    name: 'Ethereum',
    icon: '/assets/blockchainIcons/Eth.svg',
  },
  [ChainType.polygon]: {
    name: 'Polygon',
    icon: '/assets/blockchainIcons/Polygon.svg',
  },
  [ChainType.bnb]: {
    name: 'BNB Chain',
    icon: '/assets/blockchainIcons/Bnb.svg',
  },
  [ChainType.optimism]: {
    name: 'Optimism',
    icon: '/assets/blockchainIcons/Optimism.svg',
  },
  [ChainType.arbitrum]: {
    name: 'Arbitrum One',
    icon: '/assets/blockchainIcons/Arbitrum.svg',
  },
  [ChainType.ArbitrumNova]: {
    name: 'Arbitrum Nova',
    icon: '/assets/blockchainIcons/Arbitrum_Nova.svg',
  },
  [ChainType.fantom]: {
    name: 'Fantom',
    icon: '/assets/blockchainIcons/Fantom.svg',
  },
  [ChainType.avalanche]: {
    name: 'Avalanche',
    icon: '/assets/blockchainIcons/Avalanche.svg',
  },
  [ChainType.gnosis]: {
    name: 'Gnosis chain',
    icon: '/assets/blockchainIcons/Gnosis.svg',
  },
  [ChainType.solana]: {
    name: 'Solana',
    icon: '/assets/blockchainIcons/Solana.svg',
  },
  [ChainType.Terra]: {
    name: 'Terra',
    icon: '/assets/blockchainIcons/Terra.svg',
  },
  [ChainType.Near]: {
    name: 'Near',
    icon: '/assets/blockchainIcons/Near.svg',
  },
  [ChainType.Polkadot]: {
    name: 'Polkadot',
    icon: '/assets/blockchainIcons/Polkadot.svg',
  },
  [ChainType.Kusama]: {
    name: 'Kusama',
    icon: '/assets/blockchainIcons/Kusama.svg',
  },
  [ChainType.Cardano]: {
    name: 'Cardano',
    icon: '/assets/blockchainIcons/Cardano.svg',
  },
  [ChainType.Cosmos]: {
    name: 'Cosmos',
    icon: '/assets/blockchainIcons/Cosmos.svg',
  },
  [ChainType.Tron]: {
    name: 'Tron',
    icon: '/assets/blockchainIcons/Tron.svg',
  },
  [ChainType.Algorand]: {
    name: 'Algorand',
    icon: '/assets/blockchainIcons/Algorand.svg',
  },
  [ChainType.Flow]: {
    name: 'Flow',
    icon: '/assets/blockchainIcons/Flow.svg',
  },
  [ChainType.EOS]: {
    name: 'EOS',
    icon: '/assets/blockchainIcons/Eos.svg',
  },
  [ChainType.Harmony]: {
    name: 'Harmony',
    icon: '/assets/blockchainIcons/Harmony.svg',
  },
  [ChainType.Neo]: {
    name: 'Neo',
    icon: '/assets/blockchainIcons/Neo.svg',
  },
  [ChainType.Zilliqa]: {
    name: 'Zilliqa',
    icon: '/assets/blockchainIcons/Zilliqa.svg',
  },
  [ChainType.Enjin]: {
    name: 'Enjin',
    icon: '/assets/blockchainIcons/Enjin.svg',
  },
  [ChainType.Loopring]: {
    name: 'Looprin',
    icon: '/assets/blockchainIcons/Loopring.svg',
  },
  [ChainType.ImmutableX]: {
    name: 'ImmutableX',
    icon: '/assets/blockchainIcons/ImmutableX.svg',
  },
  [ChainType.Ronin]: {
    name: 'Ronin',
    icon: '/assets/blockchainIcons/Ronin.png',
  },
  [ChainType.Celo]: {
    name: 'Celo',
    icon: '/assets/blockchainIcons/Celo.svg',
  },
  [ChainType.Moonbeam]: {
    name: 'Moonbeam',
    icon: '/assets/blockchainIcons/Moonbeam.png',
  },
  [ChainType.Palm]: {
    name: 'Palm',
    icon: '/assets/blockchainIcons/Palm.svg',
  },
  [ChainType.Caduceus]: {
    name: 'Caduceus',
    icon: '/assets/blockchainIcons/Caduceus.png',
  },
  [ChainType.ZkSync]: {
    name: 'ZkSync',
    icon: '/assets/blockchainIcons/ZkSync.svg',
  },
  [ChainType.Aptos]: {
    name: 'Aptos',
    icon: '/assets/blockchainIcons/Aptos.svg',
  },
  [ChainType.Sui]: {
    name: 'Sui',
    icon: '/assets/blockchainIcons/Sui.svg',
  },
  [ChainType.StarkNet]: {
    name: 'StarkNet',
    icon: '/assets/blockchainIcons/StarkNet.svg',
  },
  [ChainType.Shardeum]: {
    name: 'Shardeum',
    icon: '/assets/blockchainIcons/Shardeum.png',
  },
  [ChainType.haqq]: {
    name: 'HAQQ',
    icon: '/assets/blockchainIcons/haqq.png',
  },
  [ChainType.metis]: {
    name: 'Metis',
    icon: '/assets/blockchainIcons/metis.png',
  },
  [ChainType.injective]: {
    name: 'Injective',
    icon: '/assets/blockchainIcons/injective.png',
  },
  [ChainType.mantle]: {
    name: 'Mantle',
    icon: '/assets/blockchainIcons/mantle.webp',
  },
  [ChainType.scroll]: {
    name: 'Scroll',
    icon: '/assets/blockchainIcons/scroll.svg',
  },
  [ChainType.base]: {
    name: 'Base',
    icon: '/assets/blockchainIcons/base.svg',
  },
  [ChainType.sei]: {
    name: 'Sei',
    icon: '/assets/blockchainIcons/Sei.png',
  },
  [ChainType.astar]: {
    name: 'Astar',
    icon: '/assets/blockchainIcons/Astar.png',
  },
};

export const BLOCK_VERIFICATION_EMAIL_HASH = {
  '@gmail.com': 'Gmail',
  '@yahoo.com': 'Yahoo',
  '@hotmail.com': 'Hotmail',
  '@outlook.com': 'Outlook',
  '@icloud.com': 'iCloud',
  '@qq.com': 'QQ',
  '@163.com': 163,
  '@126.com': 126,
  '@sina.com': 'Sina',
  '@sohu.com': 'Sohu',
  '@aol.com': 'AOL',
  '@zoho.com': 'Zoho',
  '@mail.com': 'Mail.com',
  '@yandex.com': 'Yandex',
  '@protonmail.com': 'ProtonMail',
  '@gmx.com': 'GMX',
  '@rediffmail.com': 'Rediffmail',
  '@lycos.com': 'Lycos',
  '@excite.com': 'Excite',
  '@tutanota.com': 'Tutanota',
  '@hushmail.com': 'Hushmail',
};
