import { Box, BoxProps, styled } from '@cyberlab/uikit';
import { PropsWithChildren } from 'react';

const getBorderWitdh = (width: string | number) => {
  if (typeof width === 'number') {
    return `${width}px`;
  }

  return width;
};

interface LinearBorderBoxProps extends BoxProps {
  borderWidth?: number | string;
  background?: string;
  borderColor?: string;
}

const BorderBox = styled(Box, {
  shouldForwardProp: (p) => p !== 'borderWidth' && p !== 'borderColor' && p !== 'background',
})<{
  borderWidth: string;
  background?: string;
}>(({ theme: { palette }, borderWidth, borderColor, background }) => ({
  // background color , border color
  backgroundImage: `linear-gradient(to right, ${background || palette.bg?.[0]}, ${
    background || palette.bg?.[0]
  }), ${borderColor}`,
  backgroundClip: 'padding-box, border-box',
  backgroundOrigin: 'padding-box, border-box',
  border: `${borderWidth} solid transparent`,
}));

function LinearBorderBox(props: PropsWithChildren<LinearBorderBoxProps>) {
  const {
    borderRadius = 1,
    borderWidth = 1,
    borderColor = 'linear-gradient(138deg,#B1E8DB, #0DB48C6E, #93EDD8F7, #0DB48C00)',
    background,
    children,
    ...boxProps
  } = props;

  return (
    <BorderBox
      borderRadius={borderRadius}
      borderWidth={getBorderWitdh(borderWidth)}
      borderColor={borderColor}
      background={background}
      {...boxProps}
    >
      {children}
    </BorderBox>
  );
}

export default LinearBorderBox;
