import { Flex } from '@cyberlab/uikit';
import { BoxProps } from '@mui/system';
import Image from 'next/image';

function EASBadge(props: { type: 'member' | 'org' } & BoxProps) {
  const { type } = props;
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      sx={{ '>span': { overflow: 'visible !important' } }}
      mt={-6}
      mb={2}
      borderRadius={100}
      position='relative'
      width={80}
      height={80}
      boxShadow={props.boxShadow}
    >
      <Image
        priority
        quality={100}
        src={`/assets/logos/eas-${type}.png`}
        layout='fill'
        style={{ transform: 'scale(1.5)', zIndex: 0 }}
      />
    </Flex>
  );
}

export default EASBadge;
