import LinearBorderBox from '@/component/LinearBorderBox';
import { Attestation_Type } from '@cyber/service/pwa';
import { Text } from '@cyberlab/uikit';
import { ModalProps } from '@cyberlab/uikit/src/Modal';
import { Icon } from '@link3-pwa/icons';
import { Box, Dialog, Stack } from '@mui/material';
import Image from 'next/image';
import EASBadge from './EASBadge';
import EASInfo from './Info';

type TVerifiedType = 'member' | 'org';

interface EASVerificationModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  type?: TVerifiedType;
  attestation: {
    id?: string;
    data: any;
    type?: Attestation_Type;
  };
  orgName: string;
}

const COLORS: {
  [key: string]: {
    border: string;
    shadow: string;
  };
} = {
  org: {
    border: 'linear-gradient(138deg, #F8FAE5 14.38%, #CBB279 37.67%, #E9F0D7 61.38%, #957941 84.9%)',
    shadow: '0px 20px 50px 0px rgba(172, 145, 70, 0.30), 0px 10px 10px 0px rgba(172, 145, 70, 0.15)',
  },
  member: {
    border: 'linear-gradient(138deg,#B1E8DB, #0DB48C6E, #93EDD8F7, #0DB48C00)',
    shadow: '0px 20px 50px 0px rgba(18, 182, 143, 0.30), 0px 10px 10px 0px rgba(18, 182, 143, 0.15)',
  },
};

const Title = (props: { type?: Attestation_Type }) => {
  const { type } = props;

  if (!type) return null;

  if (type === Attestation_Type.Kol) {
    return (
      <Text variant='subtitle1' bold color='brand.main'>
        Verified X Creator
      </Text>
    );
  }

  if ([Attestation_Type.Member, Attestation_Type.Email].includes(type))
    return (
      <Text variant='subtitle1' bold color='brand.main'>
        Verified Member
      </Text>
    );

  return (
    <Text
      width='fit-content'
      variant='subtitle1'
      bold
      sx={{
        backgroundClip: 'text',
        backgroundImage: 'linear-gradient(138deg, #FFD401 14.38%, #F9A500 84.9%)',
        color: 'transparent',
      }}
    >
      Verified Organzaition
    </Text>
  );
};

function EASVerificationModal(props: EASVerificationModalProps) {
  const { type = 'member', attestation, orgName, ...modalProps } = props;
  return (
    <Dialog
      PaperProps={{ sx: { bgcolor: 'transparent', background: 'none', m: 0, px: 3, overflow: 'visible' } }}
      {...modalProps}
    >
      <Stack width='calc(100vw - 48px)' position='relative' alignItems='center'>
        <LinearBorderBox width='100%' borderColor={COLORS[type].border}>
          <Stack alignItems='center' gap={3} p={3}>
            <EASBadge boxShadow={COLORS[type].shadow} type={type} />

            <Title type={attestation.type} />

            <EASInfo attestation={attestation} type={type} orgName={orgName} />

            {/* <Footer /> */}
          </Stack>
        </LinearBorderBox>

        <Icon name='icon-close-circle-line' mt={3} onClick={modalProps.onClose} />

        <Box position='absolute' bottom={48} left={0} width='100%' height={167} zIndex={1}>
          <Image src={`/assets/bg/eas-bg-${type}.svg`} layout='fill' />
        </Box>
      </Stack>
    </Dialog>
  );
}

export default EASVerificationModal;
