import { getProfileQueryParams, sleep } from '@cyber-co/utils';
import { useAttestationsQuery } from '@cyber/service/eas';
import {
  Attestation_Status,
  Attestation_Type,
  ClaimEasAttestationResponse_Status,
  OrgProfile,
  PerProfile,
  Profile,
  ProfileType,
  VerificationStatus,
  useClaimEasAttestationMutation,
  useEasAttestationQuery,
  useLazyEasAttestationQuery,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useState } from 'react';

const isOrgProfile = (data?: Partial<Profile>): data is OrgProfile => {
  return data?.type === ProfileType.Organization;
};

const isPerProfile = (data?: Partial<Profile>): data is PerProfile => {
  return data?.type === ProfileType.Personal;
};

const getAtestation = (profileData: any) => {
  if (isOrgProfile(profileData)) {
    return {
      attestation: profileData.organization.lightInfo.attestation,
      isVerified: profileData.organization.verification === VerificationStatus.Verified,
    };
  } else if (isPerProfile(profileData)) {
    return {
      attestation: profileData.owner.lightInfo.attestation,
      isVerified: profileData.owner.lightInfo.isVerified,
    };
  } else {
    return {
      attestation: undefined,
      isVerified: false,
    };
  }
};

function useEas(profileId?: string) {
  const [isClaiming, toggleIsClaiming] = useState(false);

  const { attestation, isVerified, refetch } = useEasAttestationQuery(getProfileQueryParams(profileId), {
    skip: !profileId,
    selectFromResult({ currentData, isFetching }) {
      const profileData: any = currentData?.profile.data;

      return getAtestation(profileData);
    },
    refetchOnMountOrArgChange: true,
  });

  const [queryEasStatus] = useLazyEasAttestationQuery();
  const [claimEASCaller] = useClaimEasAttestationMutation();

  //   query eas data from eas endpoint by uid
  const { atestationData } = useAttestationsQuery(
    { id: attestation?.uid! },
    {
      selectFromResult({ currentData }) {
        return {
          atestationData: currentData?.getAttestation,
        };
      },
      skip: !attestation?.uid,
    },
  );

  const checkEasStatus = async () => {
    return await queryEasStatus(getProfileQueryParams(profileId)).then((data) => {
      const profileLightInfo = (data.data?.profile.data as PerProfile)?.owner?.lightInfo;
      return (
        // only member can claim
        {
          couldClaim: profileLightInfo?.attestation?.status === Attestation_Status.Unclaimed,
          pendingClaim: profileLightInfo?.attestation?.status === Attestation_Status.Claiming,
          isClaimed: profileLightInfo?.attestation?.status === Attestation_Status.Claimed,
          claimedByOther: profileLightInfo?.attestation?.status === Attestation_Status.ClaimedByOther,
        }
      );
    });
  };

  const pollingEASStatus = async () => {
    let claimed = false;
    let count = 0;
    do {
      if (count > 20) {
        break;
      }
      claimed = await queryEasStatus(getProfileQueryParams(profileId)).then((data) => {
        return (
          // only member can claim
          (data.data?.profile.data as PerProfile)?.owner?.lightInfo?.attestation?.status === Attestation_Status.Claimed
        );
      });
      await sleep(1000);
      count++;
    } while (!claimed);
    toggleIsClaiming(false);
    return claimed;
  };

  const claimEAS = async () => {
    try {
      toggleIsClaiming(true);
      const { couldClaim, isClaimed, claimedByOther } = await checkEasStatus();
      if (isClaimed) {
        return {
          isSuccess: true,
        };
      }

      if (claimedByOther) {
        toast.error('claimed by other');
        return {
          isSuccess: false,
        };
      }

      // skip claim caller when eas is pending on claiming
      const { isSuccess, status } = await (couldClaim
        ? claimEASCaller().then((data) => {
            return {
              isSuccess:
                'data' in data && data.data.ClaimEasAttestation.Status === ClaimEasAttestationResponse_Status.Success,
              status: 'data' in data ? data.data.ClaimEasAttestation.Status : undefined,
            };
          })
        : {
            isSuccess: true,
            status: undefined,
          });

      if (isSuccess) {
        const claimed = await pollingEASStatus();
        return {
          isSuccess: claimed,
        };
      }
      return {
        isSuccess: false,
      };
    } catch (error) {
      return { isSuccess: false };
    } finally {
      toggleIsClaiming(false);
    }
  };

  return {
    isVerified: isVerified && !!attestation?.uid && attestation.type !== Attestation_Type.Member,
    attestation: {
      data: atestationData,
      id: attestation?.uid,
      type: attestation?.type,
    },
    attestationType: attestation?.type,
    couldClaim: attestation?.status === Attestation_Status.Unclaimed && attestation.type !== Attestation_Type.Member,
    isClaiming: isClaiming || attestation?.status === Attestation_Status.Claiming,
    claimEAS,
    refetch,
  };
}

export default useEas;
