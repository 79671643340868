import OrgIcon from '@/component/Icons/OrgIcon';
import { Attestation } from '@cyber/service/eas';
import { Attestation_Type } from '@cyber/service/pwa';
import { Flex, Stack, Text } from '@cyberlab/uikit';
import { format, fromUnixTime } from 'date-fns';
import Link from 'next/link';
import { PropsWithChildren, useMemo } from 'react';

const getEASExplorerDomain = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ? 'https://base-sepolia.easscan.org/attestation/view/'
    : 'https://base.easscan.org/attestation/view/';
};

const InfoItem = (props: PropsWithChildren<{ title: string }>) => {
  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Text color='text.250' variant='caption'>
        {props.title}
      </Text>

      {props.children}
    </Flex>
  );
};

const isOrgVerified = (refID?: string) => {
  return !!refID && refID !== '0x0000000000000000000000000000000000000000000000000000000000000000';
};

function EASInfo(props: {
  attestation?: {
    id?: string;
    data?: Partial<Attestation>;
    type?: Attestation_Type;
  };
  type: 'member' | 'org';
  orgName: string;
}) {
  const { attestation, type, orgName } = props;

  const attestationData = useMemo(() => {
    if (!attestation?.data?.decodedDataJson) return null;

    try {
      const parsedData = JSON.parse(attestation.data.decodedDataJson);

      return parsedData.reduce((result: any, item: any) => {
        result[item.name] = item.value.value;

        return result;
      }, {});
    } catch (error) {
      return null;
    }
  }, [attestation?.data?.decodedDataJson]);

  return (
    <Stack gap={3} width='100%' zIndex={2}>
      {attestation?.type === Attestation_Type.Kol ? (
        <InfoItem title='Twitter Handle'>
          <Flex gap={0.5}>
            <Text>@{attestationData?.twitterHandle}</Text>
          </Flex>
        </InfoItem>
      ) : (
        <InfoItem title='Organization'>
          <Flex gap={0.5}>
            <Text>{orgName}</Text> {isOrgVerified(attestation?.data?.refUID) ? <OrgIcon size={18} /> : null}
          </Flex>
        </InfoItem>
      )}

      <InfoItem title='Verification Date'>
        <Text>
          {attestation?.data?.timeCreated ? format(fromUnixTime(attestation?.data?.timeCreated), 'dd/MM/yyyy') : '-'}
        </Text>
      </InfoItem>
      <InfoItem title='EAS Link'>
        <Link passHref href={`${getEASExplorerDomain()}${attestation?.id || ''}`}>
          <Text width='60%' noWrap color='text.link'>
            {getEASExplorerDomain()}
            {attestation?.id || ''}
          </Text>
        </Link>
      </InfoItem>
    </Stack>
  );
}

export default EASInfo;
